import {atom, selector} from "recoil";

export const passoCarrinhoState = atom({
  key: "passoCarrinhoState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet((newData) => {
        console.debug("passo", newData);
      });
    },
  ],
});

export const ultimoPassoCarrinhoSelector = selector({
  key: "ultimoPassoCarrinhoSelector",
  get: ({get}) => get(passoCarrinhoState) === get(maxPassosSelector) + 1,
});

export const maxPassosSelector = selector({
  key: "maxPassosSelector",
  get: ({get}) => 3,
});
