import {
  CheckIcon,
  ChevronUpDownIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import React, {Fragment, useEffect} from "react";
import {useState} from "react";

import {
  formataTelefone,
  substituirAcentos,
  validaTelefone,
} from "../utils/textUtils";
import {Combobox, Listbox, Transition} from "@headlessui/react";
import {DDDPorEstado} from "../lib/api/ddd";
import {ArrowsRightLeftIcon} from "@heroicons/react/24/outline";
import PopupInfo from "./utils/PopupInfo";
import {pushEventToDataLayer} from "../utils/gtm";

const operadoras = [
  {nome: "Claro", id: "claro"},
  {nome: "Vivo", id: "vivo"},
  {nome: "Outra", id: "ni"},
];

function PreencheAniAquisicao({callback, ani, medium, origem}) {
  const [servicoAberto, setServicoAberto] = useState("");
  const [dadosAquisicao, setDadosAquisicao] = useState({});
  const [habilitaContinuar, setHabilitaContinuar] = useState(false);
  const [erroPortabilidade, setErroPortabilidade] = useState(null);
  const urlMudarPlano = `/?utm_source=${
    origem === "dma" ? "dma1056" : origem
  }&utm_medium=aquisicao&utm_campaign=pre_controle&ani=${ani || ""}`;
  const handleContinuar = (e) => {
    e.preventDefault();
    if (!dadosAquisicao.servico) return;

    if (
      dadosAquisicao.servico === "portabilidade" &&
      !validaTelefone(dadosAquisicao.ani)
    ) {
      setErroPortabilidade("Informe um número de celular válido.");
      return;
    }

    callback(dadosAquisicao);
  };

  const handleTrackingNovaLinha = (e) => {
    e.preventDefault(); // Impede o comportamento padrão do evento

    pushEventToDataLayer({
      event: "desambiguador_mudar_plano",
    });

    setTimeout(() => {
      window.location.href = e.target.href; // Redireciona para a URL após o atraso
    }, 400);
  };

  const handleAtualizaDados = (objetos, servico, preenchido) => {
    setHabilitaContinuar(preenchido);
    setDadosAquisicao({...dadosAquisicao, ...objetos, servico});
  };

  const handleChangeServico = (servico) => {
    setHabilitaContinuar(false);
    setServicoAberto(servico);
  };

  useEffect(() => {
    const s =
      medium === "novalinha" ? "servico-nova-linha" : "servico-portabilidade";
    handleChangeServico(s);
  }, [medium]);

  return (
    <div className="fixed z-50 w-full h-screen bg-black bg-opacity-70 top-0">
      <div className="flex h-full flex-col items-center justify-center">
        <div className="mx-8 max-w-lg bg-white text-gray-800 rounded-lg p-8 px-12">
          <form className="" onSubmit={handleContinuar}>
            <h3 className="text-lg font-light mb-4">
              Escolha o serviço que deseja realizar
            </h3>
            <div className="flex flex-col  justify-between w-full relatite ">
              <ul className="divide-y divide-gray-200">
                <li className="flex justify-between gap-x-6 py-4">
                  <div className="flex flex-col  w-full gap-x-4">
                    <div className="flex items-center py-2">
                      <input
                        type="radio"
                        id="servico-portabilidade"
                        name="servico"
                        checked={servicoAberto === "servico-portabilidade"}
                        onChange={(e) => handleChangeServico(e.target.id)}
                        className="w-5 h-5 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-secondary-tim-light checked:bg"
                      />
                      <label
                        htmlFor="servico-portabilidade"
                        className="w-full py-2 text-sm font-medium text-gray-700"
                      >
                        Trazer meu número para TIM (Portabilidade)
                      </label>
                      <PopupInfo
                        mensagem={
                          "Se você é de outra operadora, seu número será mantido na TIM e você receberá em casa seu TIMchip com plano escolhido. O CPF cadastrado deve ser o mesmo e sua linha deve estar ativa na antiga operadora."
                        }
                      />
                    </div>
                    <Transition
                      as={Fragment}
                      show={servicoAberto === "servico-portabilidade"}
                      enter="transform transition duration-[400ms]"
                      enterFrom="opacity-0 -translate-y-12"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease duration-300 transform"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-12"
                    >
                      <div>
                        {servicoAberto === "servico-portabilidade" && (
                          <Portabilidade
                            ani={ani}
                            callback={handleAtualizaDados}
                          />
                        )}
                      </div>
                    </Transition>
                    {erroPortabilidade && (
                      <p className="text-sm font-medium text-red-400">
                        {erroPortabilidade}
                      </p>
                    )}
                  </div>
                </li>
                <li className="flex justify-between gap-x-6 py-4">
                  <div className="flex flex-col  w-full gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <div className="flex items-center py-2">
                        <input
                          type="radio"
                          id="servico-nova-linha"
                          name="servico"
                          checked={servicoAberto === "servico-nova-linha"}
                          onChange={(e) => handleChangeServico(e.target.id)}
                          className="w-5 h-5 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-secondary-tim-light "
                        />
                        <label
                          htmlFor="servico-nova-linha"
                          className="w-full py- text-sm font-medium text-gray-700"
                        >
                          Quero um novo número TIM
                        </label>
                        <PopupInfo
                          mensagem={
                            "Você vai receber em casa um TIMchip com sua nova linha e um novo número com o plano escolhido."
                          }
                        />
                      </div>
                      <Transition
                        as={Fragment}
                        show={servicoAberto === "servico-nova-linha"}
                        enter="transform transition duration-[400ms]"
                        enterFrom="opacity-0 -translate-y-12"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease duration-300 transform"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-12"
                      >
                        <div>
                          {servicoAberto === "servico-nova-linha" && (
                            <NovaLinha callback={handleAtualizaDados} />
                          )}
                        </div>
                      </Transition>
                    </div>
                  </div>
                </li>
                <li className="py-4">
                  <a
                    href={urlMudarPlano}
                    onClick={handleTrackingNovaLinha}
                    className="text-base font-medium text-primary-tim hover:underline"
                  >
                    <ArrowsRightLeftIcon className="w-5 h-5 inline mr-2" />
                    Mudar meu plano TIM atual
                  </a>
                </li>
              </ul>
            </div>
            <button
              type="submit"
              disabled={!habilitaContinuar}
              className="mt-4 px-8 w-full py-3 text-xs font-extrabold text-white uppercase rounded-full bg-secondary-tim disabled:bg-gray-500"
            >
              Continuar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

function NovaLinha({callback}) {
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [dddSelecionado, setDddSelecionado] = useState(null);
  const [query, setQuery] = useState("");
  const estados = DDDPorEstado;

  const estadosFiltrados =
    query === ""
      ? estados
      : estados.filter((es) =>
          substituirAcentos(es.nome.toLowerCase()).includes(
            substituirAcentos(query.toLowerCase()),
          ),
        );

  useEffect(() => {
    let preenchido = false;

    if (estadoSelecionado?.nome && dddSelecionado) {
      preenchido = true;
    }
    callback(
      {estado: estadoSelecionado?.nome, ddd: dddSelecionado, operadora: "ni"},
      "nova-linha",
      preenchido,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoSelecionado, dddSelecionado]);

  useEffect(() => {
    if (estadoSelecionado?.ddds?.length === 1) {
      setDddSelecionado(estadoSelecionado?.ddds[0]);
    } else {
      setDddSelecionado(null);
    }
  }, [estadoSelecionado]);

  return (
    <>
      <div className="flex flex-col justify-between w-full relatite ">
        <label
          htmlFor="operadora-atual"
          className="block text-sm font-light leading-6  text-gray-900"
        >
          Selecione o estado
        </label>
        <Combobox
          value={estadoSelecionado}
          onChange={setEstadoSelecionado}
          nullable
        >
          <div className="z-50 relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden  bg-white text-left border-b border-secondary-tim outline-none text-gray-600 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-1 pr-10 text-sm leading-5 text-gray-800 focus:ring-0"
                displayValue={(estado) => estado?.nome}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg  focus:outline-none sm:text-sm">
                {estadosFiltrados.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nada encontrado.
                  </div>
                ) : (
                  estadosFiltrados.map((estado) => (
                    <Combobox.Option
                      key={estado.sigla}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-secondary-tim text-white"
                            : "text-secondary-tim"
                        }`
                      }
                      value={estado}
                    >
                      {({selected, active}) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {estado.nome}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-secondary-tim"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        {estadoSelecionado?.sigla && (
          <div>
            {estadoSelecionado?.ddds?.length === 1 && (
              <>
                <label className="block text-sm font-light leading-6 mt-3 text-gray-900">
                  DDD Selecionado
                </label>
                <span className="py-2 w-full mx-auto rounded-full border border-secondary-tim text-secondary-tim font-medium block text-center">
                  {estadoSelecionado.ddds[0]}
                </span>
              </>
            )}

            {estadoSelecionado?.ddds?.length > 1 && (
              <>
                <label className="block text-sm font-light leading-6 mt-3 text-gray-900">
                  Selecione o DDD
                </label>
                <Listbox value={dddSelecionado} onChange={setDddSelecionado}>
                  <div className="relative mt-1 z-40">
                    <Listbox.Button className="relative w-full cursor-default bg-white py-4 pl-1 pr-10 text-left border-b border-secondary-tim outline-none text-gray-800 font-medium  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">{dddSelecionado}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {estadoSelecionado?.ddds.map((ddd, dddIdx) => (
                          <Listbox.Option
                            key={dddIdx}
                            className={({active}) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-secondary-tim text-white"
                                  : "text-secondary-tim"
                              }`
                            }
                            value={ddd}
                          >
                            {({selected, active}) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {ddd}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 text-secondary-tim ${
                                      active
                                        ? "text-white"
                                        : "text-secondary-tim"
                                    } `}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

function Portabilidade({ani, callback}) {
  const [numeroTelefone, setNumeroTelefone] = useState(formataTelefone(ani));
  const [operadoraSelecionada, setOperadoraSelecionada] = useState(null);
  const [exibeOutroNumero, setExibeOutroNumero] = useState(!!ani);
  const [numeroTemporario, setNumeroTemporario] = useState(true);

  useEffect(() => {
    let preenchido = false;
    if (numeroTelefone.replace(/([^0-9])/gi, "") && operadoraSelecionada?.id) {
      preenchido = true;
    }
    callback(
      {
        ani: numeroTelefone.replace(/([^0-9])/gi, ""),
        operadora: operadoraSelecionada?.id,
        numeroTemporario,
      },
      "portabilidade",
      preenchido,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numeroTelefone, operadoraSelecionada, numeroTemporario]);

  return (
    <>
      <div className="flex flex-col justify-between w-full relatite ">
        <label
          htmlFor="ani-portabilidade"
          className="block text-sm font-light leading-6 text-gray-900"
        >
          Telefone para Portabilidade
        </label>
        <div className="flex flex-row justify-between items-center w-full relatite mb-2 ">
          <div className="flex flex-row justify-between w-full relatite">
            <input
              name="ani-portabilidade"
              type="text"
              required
              value={numeroTelefone}
              placeholder="(  )      -"
              onBlur={(e) => setNumeroTelefone(formataTelefone(e.target.value))}
              onChange={(e) => setNumeroTelefone(e.target.value)}
              className={`w-full border-b border-secondary-tim outline-none text-gray-600 font-medium `}
            />
            <PhoneIcon className="icon-input" />
          </div>
          {exibeOutroNumero && (
            <button
              onClick={() => {
                setNumeroTelefone("");
                setExibeOutroNumero(false);
              }}
              title="Outro número de telefone"
              className="text-xs uppercase font-light text-secondary-tim border border-secondary-tim rounded-full px-3 py-1 ml-2 hover:text-white hover:bg-secondary-tim"
            >
              Outro
            </button>
          )}
        </div>

        <label
          htmlFor="operadora-atual"
          className="z-10 block text-sm font-light leading-6 -mb-2 text-gray-900"
        >
          Operadora Atual
        </label>
        <Listbox
          value={operadoraSelecionada}
          onChange={setOperadoraSelecionada}
          className="z-20"
        >
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default bg-white py-4 pl-1 pr-10 text-left border-b border-secondary-tim outline-none text-gray-600 font-medium  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">
                {operadoraSelecionada?.nome}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {operadoras.map((operadora, operadoraIdx) => (
                  <Listbox.Option
                    key={operadoraIdx}
                    className={({active}) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-gray-100 text-gray-900" : "text-gray-900"
                      }`
                    }
                    value={operadora}
                  >
                    {({selected}) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {operadora.nome}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-secondary-tim">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>

        {/* <div className="py-2 z-0">
          <GerarNumeroTemporario callback={setNumeroTemporario} />
        </div> */}
      </div>
    </>
  );
}

export default PreencheAniAquisicao;
