import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {XMarkIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {dadosClienteState} from "../../atoms/dadosClienteAtom";
import {consultaCEPMyTracking, consultaCEPViaCep} from "../../lib/api/endereco";
import {processoState} from "../../atoms/processAtom";
import {DDDPorEstado, EstadoPorDDD} from "../../lib/api/ddd";

export default function Endereco({exibir, onClose}) {
  const [dadosCliente, setDadosCliente] = useRecoilState(dadosClienteState);
  const [processo] = useRecoilState(processoState);

  const [show, setShow] = useState(false);
  const [efeito, setEfeito] = useState(false);
  const [cep, setCep] = useState("");
  const [nomeRua, setNomeRua] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [observacao, setObservacao] = useState("");
  const [erroCep, setErroCep] = useState(null);
  const [loading, setLoading] = useState(false);
  const [erroComplemento, setErroComplemento] = useState(null);

  const handleClose = () => {
    if (show) {
      setTimeout(() => {
        setShow(!show);
        onClose && onClose();
      }, 1000);
    }

    setEfeito(false);
  };

  useEffect(() => {
    setShow(!!exibir);

    if (exibir) {
      setTimeout(() => setEfeito(true), 100);
    }
  }, [exibir]);

  const handleLimpaCampos = () => {
    setNumero("");
    setComplemento("");
    setNomeRua("");
    setBairro("");
    setCidade("");
    setEstado("");
  };

  const handleBuscaCep = async (cep) => {
    const subCep = cep.replace(/[ -]/gi, "");
    setErroCep(false);
    handleLimpaCampos();
    if (subCep.length < 5 || isNaN(subCep)) {
      setErroCep({msg: "CEP inválido", preenche: false});
      return;
    }
    setLoading(true);
    if (processo?.tipo_processo === "aquisicao") {
      const atuacaoMyTracking = await consultaCEPMyTracking(subCep);

      if (!atuacaoMyTracking?.consulta_rotas?.length > 0) {
        setErroCep({
          msg: "Infelizmente ainda não é possível entregar o TIM Chip na localidade informada. Por favor, acesse www.timbrasil.com.br ou vá até uma loja TIM para adquirir um dos planos",
          preenche: false,
        });
        setLoading(false);
        return;
      }
    }

    const responseCep = await consultaCEPViaCep(subCep);
    if (!responseCep) {
      setErroCep({msg: "Erro ao buscar o CEP", preenche: true});
      setLoading(false);
      return;
    }

    if (responseCep.erro) {
      setErroCep({msg: "Erro ao buscar o CEP", preenche: true});
      setLoading(false);
      return;
    }

    if (!responseCep.logradouro) {
      setErroCep({preenche: true});
    }
    console.log(
      "DDD processo",
      processo.ddd || processo.telefoneCliente?.substring(0, 2),
    );
    const DDD = processo.ddd || processo.telefoneCliente?.substring(0, 2);
    const ufDDD = EstadoPorDDD[DDD];
    console.log(DDDPorEstado);
    const nomeEstado = DDDPorEstado.find((e) => e.sigla === ufDDD)?.nome;
    if (ufDDD !== responseCep.uf) {
      setErroCep({
        msg:
          "Você está solicitando um plano para uma linha com DDD " +
          DDD +
          " - " +
          nomeEstado +
          ". Precisamos que informe um endereço de cobrança correspondente a essa localidade.",
        preenche: false,
      });
      setLoading(false);
      return;
    }

    setCep(responseCep.cep);
    setNomeRua(responseCep.logradouro);
    setBairro(responseCep.bairro);
    setCidade(responseCep.localidade);
    setEstado(responseCep.uf);

    setLoading(false);
  };

  const handleContinuar = (e) => {
    e.preventDefault();
    setErroComplemento(null);
    if (!cep || !nomeRua || !cidade || !estado) {
      setErroCep({msg: "Necessário preencher o endereço.", preenche: false});

      return;
    }

    if (complemento.length > 0 && complemento.length < 4) {
      handleComplemento();
      return;
    }

    setDadosCliente({
      ...dadosCliente,
      endereco: {
        cep,
        nomeRua,
        numero,
        complemento,
        bairro,
        cidade,
        estado,
        observacao,
        tipoLogradouro: nomeRua.split(" ")?.[0] || "rua",
      },
    });
    handleClose();
  };

  useEffect(() => {
    setErroComplemento(null);
    setErroCep(null);
    const {endereco} = dadosCliente;
    if (!endereco) return;

    setCep(endereco.cep);
    setNomeRua(endereco.nomeRua);
    setNumero(endereco.numero);
    setComplemento(endereco.complemento);
    setBairro(endereco.bairro);
    setCidade(endereco.cidade);
    setEstado(endereco.estado);
    setObservacao(endereco.observacao);
  }, [dadosCliente]);

  const handleChangeCep = (e) => {
    const value = e.target.value;
    setCep(value);

    if (value.replace(/[ -]/gi, "").length >= 8) {
      handleBuscaCep(value);
    }

    if (value.length === 0) {
      setNomeRua("");
      setNumero("");
      setComplemento("");
      setBairro("");
      setCidade("");
      setEstado("");
      setObservacao("");
    }
  };

  const handleComplemento = () => {
    setErroComplemento("O complemento deve ter pelo menos 4 caracteres.");
  };

  return (
    <>
      {show && (
        // Fundo escuro
        <div className="fixed z-50 items-end w-full h-screen bg-black bg-opacity-50 top-11">
          {/* Popup */}
          <div
            className={`z-50 fixed bottom-0 w-full p-6 transition-all duration-1000 ease-in-out bg-white rounded-t-2xl ${
              efeito ? "translate-y-0 visible" : "translate-y-full invisible"
            }`}
          >
            <div className="flex flex-col controle-tamanho-tela">
              <div className="flex flex-row justify-end w-full">
                <XMarkIcon
                  className="w-6 cursor-pointer text-secondary-tim"
                  onClick={handleClose}
                />
              </div>
              <h3 className="text-xl font-extrabold text-secondary-tim">
                Endereço completo:
              </h3>
              <form
                onSubmit={handleContinuar}
                className="flex flex-col mt-4 space-y-3"
              >
                {erroCep?.msg && (
                  <span className="text-xs text-red-600 ">{erroCep.msg}</span>
                )}
                <label className="relative">
                  <input
                    required
                    disabled={loading}
                    value={cep}
                    onChange={handleChangeCep}
                    // onBlur={handleBuscaCep}
                    type="text"
                    placeholder="CEP"
                    className="inputClaro disabled:bg-gray-400"
                  />
                  {loading && (
                    <ArrowPathIcon className="inline w-6 h-6 ml-2 icon-input animate-spin" />
                  )}
                </label>
                <input
                  required
                  value={nomeRua}
                  onChange={(e) => setNomeRua(e.target.value)}
                  type="text"
                  placeholder="Logradouro"
                  readOnly={!erroCep?.preenche}
                  className="inputClaro"
                />
                <input
                  required
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                  type="text"
                  placeholder="Número"
                  className=" inputClaro"
                />
                <div className="flex flex-col">
                  <input
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                    type="text"
                    placeholder="Complemento"
                    className=" inputClaro w-full md:w-auto"
                  />
                  <em
                    className={`ml-1 text-xs ${
                      erroComplemento ? "text-red-400" : "text-gray-500"
                    } `}
                  >
                    {erroComplemento
                      ? erroComplemento
                      : "Ex.: Casa 1 ou Apartamento 43 ou Sala 2301"}
                  </em>
                </div>

                <input
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  type="text"
                  placeholder="Bairro"
                  className="inputClaro w-full md:w-auto"
                />

                <div className="flex flex-row space-x-2">
                  <input
                    required
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    type="text"
                    placeholder="Cidade"
                    readOnly={!erroCep?.preenche}
                    className="inputClaro grow"
                  />
                  <input
                    required
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)}
                    type="text"
                    placeholder="UF"
                    readOnly={!erroCep?.preenche}
                    className="w-14 inputClaro "
                  />
                </div>
                {processo.fluxo === "aquisicao" && (
                  <input
                    value={observacao}
                    onChange={(e) => setObservacao(e.target.value)}
                    type="text"
                    placeholder="Observação para entrega"
                    className="inputClaro grow"
                  />
                )}
                <button
                  type="submit"
                  className="text-white btn bg-primary-tim-light"
                >
                  Inserir Endereço
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
