import {
  ArrowPathIcon,
  ChevronDownIcon,
  CreditCardIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import React, {useState} from "react";
import {useEffect} from "react";
import {useRecoilState} from "recoil";
import {dadosClienteState} from "../../atoms/dadosClienteAtom";
import {passoCarrinhoState} from "../../atoms/passoCarrinhoAtom";
import {planoSelecionadoState} from "../../atoms/planoSelecionadoAtom";
import {processoState} from "../../atoms/processAtom";

import {formataItemPrice, formatarData, novoToken} from "../../utils/textUtils";
import FormaPagamento from "./formaPagamento/FormaPagamento";
import {pushEventToDataLayer} from "../../utils/gtm";
import {confirmarPedido, pedidoDigital} from "../../lib/api/ofertas";
import ConfirmaTelefone from "./ConfirmaTelefone";
import {enviaCodigoWhatsapp} from "../../lib/api/confirmacaoTelefone";
import {getToken} from "../../lib/api/digipay";
import ResumoPedido from "../ResumoPedido";

const iconFormaPagamento = {
  cartao: <CreditCardIcon className="w-6 text-verde-tim" />,
  fatura: <BanknotesIcon className="w-6 text-verde-tim" />,
};

function montaPayloadPedido(processo, dadosCliente, planoSelecionado) {
  return {
    pid: processo?.processId,
    ani: processo.telefoneCliente?.replace(/([^0-9])/gi, ""),
    nomeCliente: dadosCliente.nomeCliente,
    dataNascimento:
      dadosCliente.dataNascimentoCliente &&
      formatarData(dadosCliente.dataNascimentoCliente, "DD/MM/YYYY"),
    nomeMae: dadosCliente.nomeMaeCliente,
    sexo: "M",
    tipoDocumento: "Carteira de identidade",
    numeroDocumento: dadosCliente.rgCliente,
    telefoneContato: dadosCliente.telefoneContato
      ? dadosCliente.telefoneContato?.replace(/([^0-9])/gi, "")
      : processo.telefoneCliente.replace(/([^0-9])/gi, ""),
    email: dadosCliente.emailCliente,
    temEmail: true,
    desejaInformarEmail: true,
    paisOrigem: "Brasil",
    planoDestino: planoSelecionado.planoDestino,
    fidelizado: planoSelecionado.fidelizado ? "S" : "N",
    desc: planoSelecionado.desc12M || planoSelecionado.descFid12M,
    bonusPromo: planoSelecionado.bonusPromo,
    bonusEspecial: planoSelecionado.bonus12M,
    bonusPortabilidade: planoSelecionado.bonusPortabilidade,
    bonusDedicado: planoSelecionado.bonusDedicado,
    diaVencimento: dadosCliente.diaVencimento
      ? dadosCliente.diaVencimento
      : planoSelecionado?.express
      ? "1"
      : null,
    diaVencimentoSugerido: dadosCliente.diaVencimentoSugerido,
    logradouro: dadosCliente.endereco?.nomeRua,
    numero: dadosCliente.endereco?.numero,
    complemento: dadosCliente.endereco?.complemento,
    cidade: dadosCliente.endereco?.cidade,
    uf: dadosCliente.endereco?.estado,
    cep: dadosCliente.endereco?.cep?.replace(/([^0-9])/gi, ""),
    bairro: dadosCliente.endereco?.bairro,
    tipoConta: dadosCliente.tipoConta || "Detalhada",
    formaPagamento: dadosCliente.formaPagamento || "fatura",
    cpf: dadosCliente.cpfCliente?.replace(/([^0-9])/gi, ""),
    origem: processo.origem,
    tipoLogradouro: dadosCliente.endereco?.tipoLogradouro,
    numeroPortado:
      processo?.fluxo === "aquisicao" ? processo.telefoneCliente : null,
    obsEntrega: dadosCliente.endereco?.observacao,
    tipoProcesso: processo?.tipo_processo,
    numeroTemporario: processo?.numeroTemporario,
    ddd: processo?.ddd,
    segmento: planoSelecionado.segmento,
    movimento: planoSelecionado.movimento,
  };
}

export default function DadosPagamento() {
  const [dadosCliente, setDadosCliente] = useRecoilState(dadosClienteState);
  const [passoCarrinho, setPassoCarrinho] = useRecoilState(passoCarrinhoState);
  const [aceitaContrato, setAceitaContrato] = useState(false);
  const [erro, setErro] = useState(null);
  const [loading, setLoading] = useState(false);
  const [exibirFormaPagamento, setExibirFormaPagamento] = useState([]);
  const [showFormaPagamento, setShowFormaPagamento] = useState(false);
  const [processo] = useRecoilState(processoState);
  const [planoSelecionado, setPlanoSelecionado] = useRecoilState(
    planoSelecionadoState,
  );
  const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] =
    useState(null);
  const [travarBotao, setTravarBotao] = useState(false);
  const [loadingToken, setLoadingToken] = useState(null);
  const [tokenDigipay, setTokenDigipay] = useState(null);

  const [validaTelefone, setValidaTelefone] = useState(false);
  const [tokenValidacao, setTokenValidacao] = useState(null);

  const handleAceiteContrato = () => {
    setAceitaContrato(!aceitaContrato);
  };
  const contratarPlano = async () => {
    console.debug("contratarPlano");

    setErro(null);
    if (!aceitaContrato) {
      setErro({
        msg: "É necessário ler e aceitar o contrato.",
      });
      return;
    }
    if (
      (!processo?.telefoneCliente && processo.tipo_processo !== "aquisicao") ||
      !planoSelecionado.planoDestino ||
      (!processo?.processId && !processo.dadosOferta)
    ) {
      setErro({
        msg: "Ocorreu um erro desconhecido, por favor, selecione o plano novamente.",
      });
      setTravarBotao(true);
      return;
    }

    pushEventToDataLayer({
      event: "add_payment_info",
      ecommerce: {
        payment_type: planoSelecionado?.forma_pagamento,
        currency: "BRL",
        value: formataItemPrice(planoSelecionado?.valorFinal),
        items: [
          {
            item_name: planoSelecionado?.planoDestinoExibicao,
            item_id:
              planoSelecionado?.planoDestinoExibicao +
              (planoSelecionado?.fidelizado ? "_f" : ""),
            price: formataItemPrice(planoSelecionado?.valorFinal),
            item_brand: planoSelecionado?.tipo,
            item_category: planoSelecionado?.destaque,
            quantity: 1,
            item_variant: planoSelecionado?.beneficiosPlano,
            currency: "BRL",
          },
        ],
      },
    });

    if (planoSelecionado?.express && exibirFormaPagamento?.length === 1) {
      setDadosCliente({...dadosCliente, formaPagamento: "cartao"});
      enviaCodigoValidacao();
      return;
    }

    if (
      !planoSelecionado?.express &&
      !dadosCliente.diaVencimento &&
      processo?.tipo_processo?.split("_")?.[0] !== "controle"
    ) {
      setErro({
        msg: "Necessário selecionar uma forma de pagamento e o dia de vencimento.",
      });
      return;
    }

    if (processo.tipo_processo !== "aquisicao") {
      enviaDadosPedido();
      return;
    }

    if (processo?.telefoneCliente) {
      enviaCodigoValidacao();
      return;
    }

    enviaDadosPedido();
  };

  const enviaCodigoValidacao = async () => {
    setLoading(true);

    const data = await enviaCodigoWhatsapp(processo?.telefoneCliente);
    let tk = null;
    if (data?.gerado) {
      tk = novoToken();
    }

    setTokenValidacao(tk);
    setValidaTelefone(true);
  };

  const enviaDadosPedido = async () => {
    console.debug("enviaDadosPedido");

    let payload = {};
    let fetcher = null;
    if (processo?.processId) {
      payload = montaPayloadPedido(processo, dadosCliente, planoSelecionado);
      fetcher = confirmarPedido;
    } else {
      //confirma pedido ura
      payload = montaPayloadPedido(processo, dadosCliente, planoSelecionado);
      fetcher = pedidoDigital;
    }

    setLoading(true);
    const pedido = await fetcher(payload);
    setLoading(false);
    if (pedido?.id) {
      handleSucessoContrato();
    } else {
      handleErroContrato({
        status: "error",
        msg: "Não foi possível processar o pedido. Se já fez um pedido através desse link, não é possível submeter novamente e você precisa aguardar a notificação de confirmação. Se você ainda não fez o pedido, por favor tente novamente mais tarde.",
      });
    }
  };

  const handleTelefoneValidado = () => {
    setValidaTelefone(false);
    if (planoSelecionado?.express && exibirFormaPagamento.length === 1) {
      handleAbrirFormaPagamento(exibirFormaPagamento[0]);
      return;
    }

    enviaDadosPedido();
  };

  const handleSucessoContrato = () => {
    setLoading(false);

    pushEventToDataLayer({
      event: "purchase",
      ecommerce: {
        currency: "BRL",
        value: formataItemPrice(planoSelecionado?.valorFinal),
        transaction_id: processo?.processId,
        payment_type: planoSelecionado?.forma_pagamento,
        items: [
          {
            item_name: planoSelecionado?.planoDestinoExibicao,
            item_id:
              planoSelecionado?.planoDestinoExibicao +
              (planoSelecionado?.fidelizado ? "_f" : ""),
            price: formataItemPrice(planoSelecionado?.valorFinal),
            item_brand: planoSelecionado?.tipo,
            item_category: planoSelecionado?.destaque,
            quantity: 1,
            item_variant: planoSelecionado?.beneficiosPlano,
            currency: "BRL",
          },
        ],
      },
    });

    setPassoCarrinho(passoCarrinho + 1);
  };

  const handleErroContrato = (e) => {
    setLoading(false);
    setErro(e);
  };

  const handleAbrirFormaPagamento = (formaPagamento) => {
    setFormaPagamentoSelecionada(formaPagamento);
    setPlanoSelecionado({
      ...planoSelecionado,
      forma_pagamento: formaPagamento.texto,
    });
    setShowFormaPagamento(true);
  };

  const handleClickFormaPagamento = (formaPagamento) => {
    if (planoSelecionado?.express && exibirFormaPagamento.length === 1) {
      return;
    }
    handleAbrirFormaPagamento(formaPagamento);
  };

  const handleCloseValidaTelefone = () => {
    setValidaTelefone(false);
    setTokenValidacao(null);
    setLoading(false);
  };

  const handleErroPagamento = () => {
    if (dadosCliente.diaVencimento) {
      setErro(null);
    }
  };

  useEffect(() => {
    if (planoSelecionado?.express) {
      setLoadingToken(true);
      getToken()
        .then((data) => {
          setTokenDigipay(data);
          setLoadingToken(false);
        })
        .catch((e) => {
          console.error("Erro token", e);
          setLoadingToken(false);
        });
    }
  }, [planoSelecionado]);

  useEffect(() => {
    if (
      processo?.tipo_processo !== "pre_controle" &&
      processo?.tipo_processo !== "aquisicao"
    ) {
      setExibirFormaPagamento(null);
      return;
    }
    let arrFormas = [
      {tipo: "fatura", tipoConta: "Conta Online", texto: "Fatura Digital"},
      {tipo: "fatura", tipoConta: "Detalhada", texto: "Fatura Física"},
    ];

    if (planoSelecionado?.express) {
      arrFormas = [{tipo: "cartao", texto: "Cartão de Crédito"}];
    }
    setExibirFormaPagamento(arrFormas);
  }, []);

  return (
    <div className="w-full bg-white">
      <div className="flex flex-col px-4 py-5 space-y-3 bg-white text-secondary-tim md:px-14 controle-tamanho-tela">
        <h3 className="text-xl font-extrabold">
          {exibirFormaPagamento ? "Escolha a forma " : "Forma "}de pagamento:
        </h3>
        <div className="py-2">
          {!exibirFormaPagamento && (
            <p className="text-xs">
              A forma de pagamento do seu plano atual será mantida para o novo
              plano. Se desejar alterar, após a efetivação do pedido você poderá
              entrar em contato no *144 e solicitar a mudança.
            </p>
          )}
          {/* FORMAS DE PAGAMENTO */}
          {exibirFormaPagamento?.map((f, i) => (
            <div
              onClick={() => handleClickFormaPagamento(f)}
              key={"forma_" + i}
              className={`flex flex-row items-center justify-between px-2 py-1 mr-4 border-b cursor-pointer border-b-secondary-tim ${
                dadosCliente?.tipoConta === f.tipoConta &&
                "bg-primary-tim-light bg-opacity-10"
              }`}
            >
              {f.tipo && iconFormaPagamento[f.tipo]}
              <span className="p-2 text-sm font-bold grow">{f.texto}</span>
              <ChevronDownIcon className="w-6" />
            </div>
          ))}
        </div>
        <ResumoPedido
          planoSelecionado={planoSelecionado}
          dadosCliente={dadosCliente}
        />

        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center">
            <input
              type="checkbox"
              className="w-5 h-5 border-0 rounded checked:bg-secondary-tim"
              onChange={handleAceiteContrato}
              checked={aceitaContrato}
            />
            <span className="ml-2 text-xs font-semibold text-secondary-tim">
              Confirmação de aceite do{" "}
              <a
                target="_blank"
                className="underline"
                href="https://www.tim.com.br/rj/para-voce/controle/regulamentos"
                rel="noreferrer"
              >
                regulamento
              </a>
            </span>
          </div>
        </div>
        {erro && (
          <span className="text-sm text-vermelho-erro">{erro?.msg}</span>
        )}
        <button
          disabled={loading || travarBotao}
          onClick={contratarPlano}
          className="w-full py-4 text-sm font-extrabold text-white uppercase rounded-full bg-verde-tim-dark disabled:bg-opacity-40"
        >
          {!loading ? (
            <span>Clique para Contratar</span>
          ) : (
            <ArrowPathIcon className="inline w-6 animate-spin" />
          )}
        </button>
      </div>
      {formaPagamentoSelecionada?.tipo && (
        <FormaPagamento
          formaPagamento={formaPagamentoSelecionada}
          tokenDigipay={tokenDigipay}
          loadingToken={loadingToken}
          exibir={showFormaPagamento}
          onClose={() => {
            setShowFormaPagamento(false);
            setFormaPagamentoSelecionada(null);
            setLoading(false);
            handleErroPagamento();
          }}
          callbackCartao={() => {
            return planoSelecionado?.express ? enviaDadosPedido() : null;
          }}
        />
      )}
      {validaTelefone === true && (
        <ConfirmaTelefone
          callbackValidado={handleTelefoneValidado}
          envioInicial={"whatsapp"}
          tokenInicial={tokenValidacao}
          onClose={handleCloseValidaTelefone}
        />
      )}
    </div>
  );
}
